import {
  APTOS_NODE_API_KEY,
  APTOS_INDEXER_API_URL,
  NODE_URL,
} from '@/constants';
import { Aptos, AptosConfig } from '@aptos-labs/ts-sdk';

const config = new AptosConfig({
  indexer: `${APTOS_INDEXER_API_URL}/v1/graphql`,
  fullnode: NODE_URL,
  clientConfig: {
    API_KEY: APTOS_NODE_API_KEY,
  },
});
const client = new Aptos(config);

export function useAptosV2Client() {
  return {
    client,
  };
}
